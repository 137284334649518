body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html{
  scroll-behavior: smooth;
}
html h1{
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}
html span, html p{
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

:root {
  --green: #008a45;
  --green2: #006934;
  --green3: #002E02;
  --yellow: #FBE03B;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
