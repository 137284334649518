.intro{

    .first-block{
        height: 92vh;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        .content{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 90%;
            width: 100%;
            gap: 20%;

            .text{
                width: 50%;
                border-radius: 20px;
                color: white;
                text-shadow: -2px 2px 5px #000000;
                background-color: rgba(0, 0, 0, 0.7);
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-evenly;
                gap: 20px;
    
                @media(max-width: 700px){
                    width: 80%;
                }
    
                h1{
                    font-size: 76px;
                    margin: 0;
                    font-family: 'Italianno', cursive;
    
                    @media(max-width: 700px){
                        font-size: 50px;
                    }
                }
    
                .grey-line{
                    height: 1px;
                    width: 50%;
                    background-color: rgb(162, 162, 162);
                }
    
                h2{
                    margin: 0;
                    font-family: 'Italianno', cursive;
                    font-weight: 200;
                    font-size: 38px;
    
                    @media(max-width: 700px){
                        font-size: 20px;
                        width: 80%;
                    }
                }
            }

            a{
                text-decoration: none;

                .more-btn{
                    height: 100px;
                    width: 15vw;
/*                     border: 7px solid var(--yellow);
 */                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    background-color: rgba(0, 0, 0, 0.7);
                    transition: .3s;
                    border-radius: 20px;
                    box-shadow: inset 2 -10px 10px black;
                    border: 8px solid var(--green);
        
                    @media(max-width: 1400px){
                        width: 40vw;
                    }
                    @media(max-width: 700px){
                        width: 40vw;
                        height: 10vw;
                    }
        
                    &:hover{
                        background-color: var(--green);

                        /* .btn-text{
                            color: black;
                        } */
                    }
        
                    .btn-text{
                        color: white;
                        font-weight: 500;
                        font-size: 30px;
                        font-family: 'Poppins', sans-serif;
                    }
                }
            }
        }

        
    }

    .second-block{
        height: 92vh;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-bottom: 5%;

        @media(max-width: 700px){
            height: fit-content;
        }

        .introduction{
            padding-top: 5%;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-around;
            color: white;

            @media(max-width: 700px){
                flex-direction: column;
                align-items: center;
                gap: 50px;
            }

            h1{
                margin: 20px 0;
                font-size: 36px;
                text-shadow: -2px 2px 10px #000000;

                @media(max-width: 1400px){
                    text-shadow: none;
                }
            }

            img{
                border-radius: 50%;
                background-color: white;
                height: 125px;
                width: 125px;
            }

            .about{
                margin: 0;
                font-size: 1.3em;
                width: 40%;
                display: flex;
                flex-direction: column;
                align-items: center;

                @media(max-width: 1400px){
                    font-size: 1em;
                }
                @media(max-width: 700px){
                    width: 80%;
                }

                p{
                    white-space: pre-wrap;
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    gap: 20px;
                    text-shadow: -2px 2px 10px #000000;
                    background-color: var(--green2);
                    border-radius: 10px;
                    text-align: left;
                    padding: 3%;
                    color: white;
                    transition: .3s;
                    margin: 0;

                    @media (max-width: 700px) {
                        background-color: var(--green);
                    }

                    &:hover{
                        transform: scale(1.05);
                    }
                }
            }

            .values{
                width: 40%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;

                @media(max-width: 700px){
                    width: 80%;
                }

                p{
                    white-space: pre-wrap;
                    display: flex;
                    flex-direction: column;
                    text-align: left;
                    padding: 3%;
                    transition: .3s;
                    gap: 20px;
                    border-radius: 10px;
                    font-size: 1.3em;
                    margin: 0;
                    background-color: var(--yellow);
                    color: black;
                    text-shadow: -2px 2px 50px #ffffff;
                    width: 100%;

                    @media(max-width: 1400px){
                        font-size: 1em;
                    }

                    &:hover{
                        transform: scale(1.05);
                    }
                }
            }
        }
    }
    .split-bar{
        height: 8vh;
        width: 100%;
        background-color: var(--green2);
    }
    .edit-button{
        color: black;
        cursor: pointer;
    }
}