.gallery-modal{
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    display: flex;
    justify-content: center;
    align-items: center;

    .modal-window{
        height: 80%;
        width: 40%;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;

        @media(max-width: 700px){
            width: 70%;
        }

        .close-btn{
            position: absolute;
            top: 0;
            right: 0;
            color: white;
        }

        .modal-content{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 25px;
            width: 100%;
            height: 100%;

            img{
                height: auto;
                max-height: 100%;
                width: auto;
                max-width: 100%;
                border-radius: 10px;
            }
        }
    }
}