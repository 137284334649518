.events-press{
    position: relative;
    display: flex;
    justify-content: space-around;
    height: 100vh;
    background-color: var(--green3);
    padding: 5% 0;

    @media(max-width: 700px){
        flex-direction: column;
        align-items: center;
        height: fit-content;
    }

    .title{
        display: flex;
        align-items: flex-start;
        justify-content: center;
        gap: 20px;
        margin-bottom: 10%;

        h1{
            color: white;
            text-shadow: -2px 2px 3px #000000;
            font-size: 3em;
        }

        img{
            height: 50px;
            align-self: center;
        }
    }

    

    .events{
        width: 40%;
        height: 100%;

        @media(max-width: 700px){
            width: 95%;
            margin-bottom: 15%;
        }
        
        h2{
            color: white;
            margin: 0;
        }
        
        .events-grid{
            display: flex;
            height: 80%;
            gap: 5%;

            @media (max-width: 1400px) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
            }

            @media (max-width: 700px) {
                display: flex;
                flex-direction: column;
            }

            .event{
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 100%;
                align-items: center;
                color: white;
                gap: 10%;

                @media (max-width: 1400px) {
                    gap: 20%;
                }

                @media (max-width: 800px) {
                    height: fit-content;
                }
                @media (max-width: 800px) {
                    gap: 0;
                }

                .event-text{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 15%;
                    width: 100%;
                    
                    @media (max-width: 1400px) {
                        font-synthesis: 25%;
                    }

                    h2{
                        height: 100%;
                        width: 100%;

                        @media (max-width: 1400px) {
                            font-size: 1.2em;
                            gap: 35%;
                        }
                    }
    
                    p{
                        margin-bottom: 20px;
                    }

                }

                

                img{
                    width: 60%;
                    transition: .3s;

                    @media (max-width: 800px) {
                        width: 80%;
                    }

                    @media (max-width: 700px) {
                        margin-bottom: 50px;
                    }

                    @media (min-width: 1400px) {
                        &:hover{
                            transform: scale(2) translateY(15%);
                        }
                    }
                    
                }

                .edit-button{
                    cursor: pointer;
                }
            }
        }        
    }

    .press{
        width: 40%;
        height: 100%;

        @media(max-width: 700px){
            width: 80%;
        }

            .sub-press-grid{
                display: flex;
                flex-direction: column;
                width: 100%;
                gap: 20px;

                @media (max-width: 700px) {
                    display: flex;
                    flex-direction: column;
                }

                .article{
                    position: relative;
                    width: 100%;
                    
                    @media(max-width: 700px){
                        height: 100%;
                        width: 100%;
                    }

                    .edit-button{
                        position: absolute;
                        top: 0;
                        right: 0;
                        color: white;
                        cursor: pointer;
                    }

                    a{
                        text-decoration: none;
                        text-shadow: none;
                        color: black;
                    }
            
                    .text{
                        background-color: var(--yellow);
                        display: flex;
                        flex-direction: column;
                        column-gap: 5%;
                        gap: 10px;
                        padding: 2%;
                        border-radius: 10px;
                        word-wrap: break-word;
                        width: 100%;
            
                        .date, .author{
                            font-weight: bold;
                        }
                    }
                }
            }
    }
}