.home{
    position: relative;

    .top-arrow{
        position: fixed;
        bottom: 5%;
        right: 2%;
        z-index: 50;
        width: 5em;
        height: 5em;
        cursor: pointer;
    }

}