.header {
    height: 8vh;
    width: 100%;
    position: sticky;
    top: 0;
    left: 0;
    background-color: var(--green2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 50;

    .nav-links {
        display: flex;
        width: 50%;
        height: 100%;
        justify-content: space-between;

        @media(max-width: 1400px){
            width: 100%;
        }

        .link {
            text-decoration: none;
            color: white;
            width: 25%;
            height: 100%;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 400;
            font-size: 1.5em;
            transition: .3s;

            @media(max-width: 700px){
                font-size: 1em;
                width: 100%;
            }

            &:hover{
                background-color: var(--green3);
            }
        }

        .menu-wrapper {
            position: relative;
            text-align: center;

            a{
                text-decoration: none;
                color: white;
            }

            &:hover .dropdown-list{
                visibility: visible;
            }
        }

        .menu-parent {
            display: block;
        }

        .dropdown-list {
            visibility: hidden;
            position: absolute;
            list-style: none;
            padding: 0;
            width: 100%; 
            height: 200%;
            top: 100%;
            margin: 0;
            left: 0; // Centrage horizontal du menu déroulant
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
            background-color: #008a45;

            li {
                height: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                
                &:hover{
                    background-color: #006934;
                }

                a{
                    color: white;
                text-decoration: none;
                }
            }
        }
    }

    .socials{
        display: flex;
        justify-self: flex-end;
    }
}
