.gallery{
    background-color: var(--green3);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    gap: 5%;
    padding: 2% 0;

    @media(max-width: 1400px){
        padding: 5% 0;
        height: fit-content;
    }

    .text{
        background-color: var(--green2);
        border-radius: 10px;
        font-size: 1.1em;
        width: 50%;
        padding: 2%;
        text-shadow: -2px 2px 10px #000000;
        color: white;

        @media(max-width: 1400px){
            font-size: 1rem;
            margin-bottom: 5%;
        }

        @media(max-width: 700px){
            width: 80%;
            margin-bottom: 5%;
        }

        p{
            white-space: pre-wrap;
            margin: 0;
        }
    }

    .gallery-container{
        overflow: hidden;
        width: 80%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        /* justify-items: center;
        align-items: center; */
        gap: 20px;
        padding: 2% 0;

        @media (max-width: 700px) {
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        .card{
            height: 200px;
            width: 100%;
            border-radius: 10px;
            object-fit: cover;
            box-shadow: -2px 2px 5px black;
            cursor: pointer;
            overflow: hidden;

            img{
                object-fit: cover;
                height: 100%;
                width: 100%;
                transition: 0.3s;

                &:hover{
                    transform: scale(1.1);
                }
            }

            @media (max-width: 700px) {
                height: 100vw;
            }

            
        }  
        
        .card:nth-child(4){
            grid-column: 1/3;
        }
        .card:nth-child(7){
            grid-column: 2/4;
        }
        .card:nth-child(11){
            grid-column: 1/3;
        }
        .card:nth-child(17){
            grid-column: 2/4;
        }
        .card:nth-child(18){
            grid-column: 1/4;
        }
    }

    .edit-button{
        position: absolute;
        bottom: 2%;
        left: 50%;
        transform: translateX(-50%);
        color: white;
        cursor: pointer;
    }
}