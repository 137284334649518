.contact{
    height: 92vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background-image: url('../../assets/roseraie.webp');
    background-position: 0 50%;

    @media (max-width: 700px) {
        height: auto;
        background-position: 80% 50%;
    }

    .foot{
        background-color: var(--green2);
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 10px;
        font-size: 12px;

        .foot-text{
            display: flex;

            .socials{
                display: flex;
                align-self: center;
                gap: 20px;
    
                .instagram{
                    color: white;
                }
            }
        }
        
        

        a{
            text-decoration: none;
        }

        span{
            color: white;
            display: inline-block;
            align-self: center;
        }
        .site-maker span{
            text-decoration: underline;
        }
    }

    /* &::before{
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.7);
    } */

    img{
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    .contact-content{
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;

        @media (max-width: 700px) {
            flex-direction: column;  
            align-items: center;     
            padding-top: 5%;
        }

        .contact-info{
            height: 100%;
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: white;
            
            @media (max-width: 700px) {
                width: 80%;
            }

            .text{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
                text-align: center;
                gap: 20px;
                background-color: rgba(0, 0, 0, 0.7);
                padding: 2%;
                height: 80%;
                width: 80%;
                border-radius: 10px;
                box-shadow: -2px 2px 5px black;
                transition: .3s;

                &:hover{
                    background-color: rgba(0, 0, 0, 0.9);
                }

                @media (max-width: 800px) {
                    height: 90%;
                }

                @media (max-width: 700px) {
                    padding: 10%;
                }
    
                .address{
                    font-size: 2em;
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    @media (max-width: 1400px) {
                        font-size: 1.5em;
                    }

                    @media (max-width: 800px) {
                        flex-direction: column;
                    }

                    @media (max-width: 700px) {
                        flex-direction: column;
                        font-size: 1.5em;
                    }

                    .icon{
                        background-color: var(--green);
                        color: white;
                        display: flex;
                        align-self: flex-start;
                        align-items: center;
                        justify-content: center;
                        height: 50px;
                        width: 50px;
                        border-radius: 50%;
                        flex-shrink: 0; /* Added to prevent shrinking */

                        @media (max-width: 800px) {
                            height: 30px;
                            width: 30px;
                            align-self: center;
                        }

                        @media (max-width: 700px) {
                            align-self: center;
                        }
                    }

                    /* .address-text{
                        width: auto;
                        display: inline-block;

                        @media (max-width: 800px) {
                            width: 90%;
                        }
                    } */
                }
                
                .mail{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    
                    p{
                        font-size: 1.5em;

                        @media (max-width: 1400px) {
                            font-size: 1em;
                        }
                    }

                    .mail-link{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: white;
                        font-size: 2em;
                        text-decoration: none;
                        width: fit-content;
                        gap: 10px;

                        &:hover .icon{
                            background-color: var(--yellow);
                            color: black;
                        }

                        @media (max-width: 1400px) {
                            font-size: 1.5em;
                        }
                        @media (max-width: 800px) {
                            flex-direction: column;
                        }

                        @media (max-width: 700px) {
                            flex-direction: column;
                            font-size: 1.5em;
                        }

                        .icon{
                            background-color: var(--green);
                            color: white;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 50px;
                            width: 50px;
                            border-radius: 50%;
                            transition: .3s;

                            @media (max-width: 800px) {
                                height: 30px;
                                width: 30px;
                            }
                        }
                    }                     
                }
    
                .asso{
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    @media (max-width: 700px) {
                        width: 100%;
                    }
    
                    a{
                        width: 50%;

                        @media (max-width: 800px) {
                            width: 80%;
                        }
                    }
    
                    .asso-img{
                        overflow: hidden;
                        border-radius: 5px; 
                        margin-top: 20px;
                        height: 8vw;

                        @media (max-width: 800px) {
                            height: 80%;
                            width: 100%;
                        }
                        
                        @media (max-width: 700px) {
                            height: 90%;
                            width: 100%;
                        }

                        img{
                            
                            object-fit: fill;  
                            transition: .3s;
                            border-radius: 5px; 
                        }

                        &:hover img{
                            transform: scale(1.1);
                        }
                    }
                }
            }
    
            
        }
    
        .access{
            height: 100%;
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            @media (max-width: 700px) {
                width: 100%;
                height: 50vh;
            }

            .iframe-background{
                width: 80%;
                height: 80%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: rgba(0, 0, 0, 0.7);
                padding: 2%;
                border-radius: 10px;
                box-shadow: -2px 2px 5px black;
                transition: .3s;

                &:hover{
                    background-color: rgba(0, 0, 0, 0.9);
                }

                iframe{
                    border-radius: 10px;
                    width: 90%;
                    height: 90%;
                    border: none;
                    outline: none;
                    border-radius: 10px;
                }
            }
        }
    }
}