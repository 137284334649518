.modal-container{
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    display: flex;
    justify-content: center;
    align-items: center;

    .modal-window{
        height: 95%;
        width: 40%;
        background-color: white;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;

        .close-btn{
            position: absolute;
            top: 2%;
            right: 2%;
        }

        .modal-content{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 25px;
            width: 100%;
            height: 90%;

            .delete-btn{
                position: absolute;
                color: black;
                background-color: white;
                border-radius: 50%;
                top: 2%;
                right: 2%;
            }

            input{
                border-radius: 5px;
                height: 5%;
            }

            .file-loader{
                border: 1px solid black;
                border-radius: 5px;
                height: 5%;
            }

            .sub-title{
                height: 15%;
            }

            textarea{
                width: 90%;
                max-width: 90%;
                height: 20%;
            }

            .first-img, .second-img{
                height: 30%;
                width: 50%;
                object-fit: cover;
            }

            .submit-btn{
                margin-top: auto;
                height: 5%;
                width: 25%;
            }

            .slider-grid{
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: repeat(2,1fr);
                width: 80%;
                height: 80%;

                .image-card{
                    position: relative;
                    height: 100%;
                    width: 100%;

                    img{
                        border: 1px solid black;
                        object-fit: cover;
                        height: 100%;
                        width: 100%;
                    }
                }
            }
            .gallery-grid{
                display: flex;
                flex-wrap: wrap;
                width: 90%;
                height: 30%;
                padding: 10px;
                gap: 10px;
                overflow: auto;
                
                .image-card{
                    position: relative;
                    height: 50%;

                    img{
                        border: 1px solid black;
                        object-fit: cover;
                        max-height: 80%;
                        width: 100%;
                    }
                }

                .galText{
                    height: 50%;
                }
            }
        }
    }
}